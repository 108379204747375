export const transitions = {
  INQUIRE_WITHOUT_PAYMENT: 'transition/inquire-without-payment',
};

export const states = {
  INITIAL: 'initial',
  FREE_INQUIRY: 'free-inquiry',
};

export const graph = {
  id: 'default-chat/release-1',

  initial: states.INITIAL,

  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE_WITHOUT_PAYMENT]: states.FREE_INQUIRY,
      },
    },
    [states.FREE_INQUIRY]: { type: 'final' },
  },
};

export const isRelevantPastTransition = transition => {
  return [transitions.INQUIRE_WITHOUT_PAYMENT].includes(transition);
};

export const isCustomerReview = transition => {
  return false;
};

export const isProviderReview = transition => {
  return false;
};

export const isPrivileged = transition => {
  return false;
};

export const isCompleted = transition => {
  return false;
};

export const isRefunded = transition => {
  return false;
};

export const statesNeedingProviderAttention = [];
